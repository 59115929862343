<template>
    <div v-if="breadcrumbData && breadcrumbData.length > 0">
        <ol class="flex flex-wrap gap-1.5 mb-5">
            <li class="flex gap-1.5 items-center" v-for="(item, index) in breadcrumbData" :key="index">
                <div v-if="item.url" class="text-sm" :class="[isClass(index)]">
                    <template v-if="isType(item?.url) === 'a'">
                        <a :href="item?.url || '/'">
                            <p class="flex-1">
                                {{ item?.title }}
                            </p>
                        </a>
                    </template>
                    <template v-else>
                        <NuxtLink :to="item?.url || '/'">
                            <p class="flex-1">
                                {{ item?.title }}
                            </p>
                        </NuxtLink>
                    </template>
                </div>
                <div v-else class="text-sm" :class="[isClass(index)]">{{ item.title }}</div>
                <span v-if="breadcrumbData.length !== index + 1" :class="[isClass(index)]">/</span>
            </li>
        </ol>
        <div class="breadcrumbs" v-html="breadcrumbRender"></div>
    </div>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
const props = defineProps({
    breadcrumb: {
        type: Array as any
    },
    isProduct: {
        type: Boolean
    },
    isLight: {
        type: Boolean
    },
    isDetail: {
        type: Boolean,
        default: false
    }
})
const resBreadcrumb = ref(props.breadcrumb)

const isType = (url: any) => {
    if (url && url.includes('http')) return 'a'
    return resolveComponent('NuxtLink')
}

const isClass = (index: any) => {
    if (index == props.breadcrumb.length - 1) {
        if (props.isLight) {
            return 'text-blacks/70'
        } else {
            return 'text-white'
        }
    } else {
        if (props.isLight) {
            return 'text-blacks/70'
        } else {
            return 'text-white'
        }
    }
}

const breadcrumbData = computed(() => {
    if (props.isDetail) {
        return resBreadcrumb.value.slice(0, resBreadcrumb.value.length - 1)
    } else {
        return resBreadcrumb.value
    }
})

const breadcrumbRender = computed(() => {
    const itemList = resBreadcrumb.value?.map((item: any, index: any) => {
        return {
            '@type': 'ListItem',
            position: index + 1,
            name: item.name,
            item: item.item
        }
    })
    return `<script type="application/ld+json">{"@context": "https://schema.org","@type": "BreadcrumbList","itemListElement": ${JSON.stringify(
        itemList
    )}}<\/script>`
})
</script>
